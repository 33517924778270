<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="12" align-md class="bottom-margin">
        <display-managements :dairy-id="dairyId"></display-managements>
      </v-col>
      <v-col cols="12" xl="12" align-md class="bottom-margin">
        <display-encashments :id="dairyId"></display-encashments>
      </v-col>
      <v-col cols="12" xl="12" align-md class="bottom-margin">
        <display-affiliations :id="dairyId"></display-affiliations>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn id="btn-edit-cancel" color="secondary" class="button-margin button-top-margin shadow-none" @click="navigateBackToSearch()"
          ><span v-html="$t('dairies_back_button')"
        /></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import displayManagements from './displayManagements.vue'
import displayAffiliations from './displayAffiliations.vue'
import displayEncashments from './displayEncashments.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'dairiesRelations',
  components: {
    displayManagements,
    displayEncashments,
    displayAffiliations
  },
  data() {
    return {
      dairyId: parseInt(this.$route.params.id)
    }
  },
  methods: {
    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_dairies_search'
        })
        .catch((e) => {
          /* duplicated route */
        })
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.bottom-margin {
  margin-bottom: $spacer-sm;
}
</style>
