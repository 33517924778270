<template>
  <v-container>
    <v-form @submit="onSubmit">
      <v-row v-if="!readonly">
        <v-col>
          <v-switch v-model="editMode" id="editContact" :label="$t('masterdata_dairies_contact_edit')" color="info"></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" xl="6">
          <v-form :disabled="!editMode">
            <v-row>
              <v-col cols="12">
                <!-- Anrede -->
                <v-text-field
                  v-model="dairyAddress.salutation"
                  type="text"
                  id="search_ApplicationUser_salutation"
                  :label="$t('masterdata_dairies_contact_salutation')"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <!-- Name/Firma -->
                <v-text-field
                  v-model="dairyAddress.name1"
                  type="text"
                  id="masterdata_dairies_contact_name"
                  :label="$t('masterdata_dairies_contact_name')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <!-- Vorname / Zusatz -->
                <v-text-field
                  v-model="dairyAddress.name2"
                  type="text"
                  id="masterdata_dairies_contact_fistname"
                  :label="$t('masterdata_dairies_contact_fistname')"
                />
              </v-col>
              <v-col cols="12">
                <!-- zuhanden -->
                <v-text-field
                  v-model="dairyAddress.attention"
                  type="text"
                  id="masterdata_dairies_contact_attn"
                  :label="$t('masterdata_dairies_contact_attn')"
                />
              </v-col>
              <v-col cols="12">
                <!-- Strasse -->
                <v-text-field
                  v-model="dairyAddress.address1"
                  type="text"
                  id="masterdata_dairies_contact_street"
                  :label="$t('masterdata_dairies_contact_street')"
                />
              </v-col>
              <v-col cols="12">
                <!-- Postfach -->
                <v-text-field
                  v-model="dairyAddress.address2"
                  type="text"
                  id="masterdata_dairies_contact_pobox"
                  :label="$t('masterdata_dairies_contact_pobox')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <!-- PLZ -->
                <v-text-field
                  v-model.trim="dairyAddress.zip"
                  type="number"
                  id="zip"
                  name="zip"
                  :label="$t('masterdata_dairies_contact_zip')"
                  :rules="[zipRule, rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <!-- Ort-->
                <v-combobox
                  v-model="dairyAddress.locality"
                  id="locality"
                  name="locality"
                  :items="localities"
                  item-title="localityLong"
                  item-key="localityLong"
                  item-value="localityLong"
                  :label="$t('masterdata_dairies_contact_location')"
                  :rules="[localityRule, rules.required]"
                  density="compact"
                  :return-object="false"
                ></v-combobox>
              </v-col>
              <v-col cols="12">
                <!-- Land -->
                <v-select
                  v-model="dairyAddress.country"
                  :items="countries"
                  :item-title="this.$getLangKey()"
                  item-value="name"
                  id="masterdata_dairies_contact_country"
                  :label="$t('masterdata_dairies_contact_country')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <!-- Sprache-->
                <v-select
                  v-model="dairyAddress.language.id"
                  :items="languages"
                  id="masterdata_dairies_contact_language"
                  :label="$t('masterdata_dairies_contact_languageCode')"
                  :item-title="this.$getLangKey()"
                  item-value="id"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <!-- E-Mail-->
                <div class="d-flex">
                  <v-text-field
                    v-model="dairyAddress.email"
                    type="email"
                    id="masterdata_dairies_contact_email"
                    :label="$t('masterdata_dairies_contact_email')"
                    :rules="[rules.email]"
                  />
                  <a :href="`mailto:` + dairyAddress.email">
                    <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <!-- Telefon 1-->
                <div class="d-flex">
                  <v-text-field
                    v-model="dairyAddress.phone1"
                    type="phone"
                    id="masterdata_dairies_contact_phone1"
                    :label="$t('masterdata_dairies_contact_phone1')"
                  />
                  <a :href="`tel:` + $formatPhone(dairyAddress.phone1)">
                    <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <!-- Hinweis Telefon 1-->
                <v-text-field
                  v-model="dairyAddress.phone1Hint"
                  type="text"
                  id="masterdata_dairies_contact_phone1Hint"
                  :label="$t('masterdata_dairies_contact_phone1Hint')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <!-- Telefon 2-->
                <div class="d-flex">
                  <v-text-field
                    v-model="dairyAddress.phone2"
                    type="phone"
                    id="masterdata_dairies_contact_phone2"
                    :label="$t('masterdata_dairies_contact_phone2')"
                  />
                  <a :href="`tel:` + $formatPhone(dairyAddress.phone2)">
                    <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <!-- Hinweis Telefon 2-->
                <v-text-field
                  v-model="dairyAddress.phone2Hint"
                  type="text"
                  id="masterdata_dairies_contact_phone2Hint"
                  :label="$t('masterdata_dairies_contact_phone2Hint')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <!-- Telefon 3-->
                <div class="d-flex">
                  <v-text-field
                    v-model="dairyAddress.phone3"
                    type="phone"
                    id="masterdata_dairies_contact_phone3"
                    :label="$t('masterdata_dairies_contact_phone3')"
                  />
                  <a :href="`tel:` + $formatPhone(dairyAddress.phone3)">
                    <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <!-- Hinweis Telefon 3-->
                <v-text-field
                  v-model="dairyAddress.phone3Hint"
                  type="text"
                  id="masterdata_dairies_contact_phone3Hint"
                  :label="$t('masterdata_dairies_contact_phone3Hint')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col cols="12" sm="12" md="12" xl="6">
          <!-- Addressvorschau -->
          <address-preview v-bind:address="dairyAddress"></address-preview>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        <v-btn id="btn-submit" :disabled="!editMode" v-if="!readonly" color="primary" class="button-margin button-top-margin shadow-none" @click="onSubmit()">
          <span v-html="$t('dairies_new_dairy_save')" />
        </v-btn>
        <v-btn
          id="btn-submit-close"
          :disabled="!editMode"
          v-if="!readonly"
          color="secondary"
          class="button-margin button-top-margin shadow-none"
          @click="submitAndClose()"
        >
          <span v-html="$t('masterdata_dairies_overview_save_and_close')" />
        </v-btn>
        <v-btn id="btn-edit-cancel" color="secondary" class="button-margin button-top-margin shadow-none" @click="navigateBackToSearch()"
          ><span v-html="$t('dairies_back_button')"
        /></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import addressPreview from '../masterdata/addressPreview'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'
import { privileges } from '@/services/privileges'
import _ from 'lodash'
import { useCountriesStore } from '@/store/enums/CountriesStore'
import { BaseAddressType, BaseAddress } from '@/views/contacts/contact'

let adr = new BaseAddress()

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'dairiesAddresses',
  components: { addressPreview },
  props: ['defaultAddressId'],
  data() {
    return {
      dairyAddress: {
        ...adr,
        ...{
          country: undefined,
          locality: {
            localityLong: undefined
          },
          language: {
            id: 1
          }
        }
      } as BaseAddressType, // defaultData löst rule-validation aus.
      editMode: false,
      fieldDef: {
        zip: {
          min: 4,
          max: 4
        },
        locality: {
          min: 2,
          max: 40
        }
      },
      localities: [],
      rules: {
        required: (value) => !!value || this.$t('form_field_required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('form_field_invalid_email')
        }
      }
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/dairies', permission: 'write' })
    },
    countries() {
      return useCountriesStore().Countries
    },
    languages() {
      useLanguagesStore().languages
    }
  },
  methods: {
    async onSubmit() {
      try {
        const result = await this.axios.patch(
          apiURL + '/localUnitAddresses/' + this.dairyAddress.id,
          {
            ..._.omit(this.dairyAddress, ['name', 'houseNo', 'formattedAddress', 'street', 'formattedName']),
            locality: this.dairyAddress.locality
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('masterdata_legal_entity_edit_toast_success')
          })
        }
      } catch (e) {
        showError(e)
      }
    },
    zipRule() {
      if (typeof parseInt(this.dairyAddress.zip) != 'number') {
        return false
      }
      //im schweizer range
      if (this.dairyAddress.zip < 999 || this.dairyAddress.zip > 9999) {
        return false
      }
      //für die Plz konnte ein Ort aufgelöst werden
      return this.localities?.length > 0
    },
    localityRule() {
      if (this.dairyAddress.locality.length >= this.fieldDef['locality'].min && this.dairyAddress.locality.length <= this.fieldDef['locality'].max) {
        return true
      }
      return false
    },
    submitAndClose() {
      this.onSubmit()
      this.back()
    },
    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_dairies_search'
        })
        .catch(() => {
          /* duplicated route */
        })
    }
  },
  watch: {
    'dairyAddress.zip': async function (newVal) {
      if (newVal >= 1000) {
        const localityByZip = await this.axios.get(apiURL + '/postcodes/findByZip', { params: { zip: newVal } }) //immer alle
        this.localities = localityByZip.data.content

        if (this.dairyAddress.locality == null && this.localities.length == 1) {
          this.dairyAddress.locality = this.localities[0].localityLong
        }
      }
    }
  },
  async mounted() {
    useCountriesStore().fetchCountries()
    try {
      const response = await this.axios.get(apiURL + '/dairies/' + this.$route.params.id + '' + '/defaultAddress', {
        headers: { Accept: 'application/json' }
      })
      this.dairyAddress = response.data
    } catch (e) {
      this.dairyAddress = []
      showError(e)
    }
  }
})
</script>
