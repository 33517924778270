<template>
  <div>
    <h2 v-html="$t('encashments_title')"></h2>
    <v-row>
      <v-col>
        <div v-if="encashments && encashments.length > 0">
          <v-data-table-server
            density="compact"
            :headers="encashmentFields"
            :items="encashments"
            :hide-default-footer="true"
            disable-pagination
            :loading="loading"
            class="custom-simple-table"
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              <dbmDatePicker class="nomessage" v-model="item.validFrom" :required="true" @change="updateEncashment(item)"></dbmDatePicker>
            </template>
            <template v-slot:[`item.validUntil`]="{ item }">
              <dbmDatePicker class="nomessage" v-model="item.validUntil" :clearable="true" @change="updateEncashment(item)"></dbmDatePicker>
            </template>
            <template v-slot:[`item.delete`]="{ item }" v-if="$privileges.has({ path: '/encashments', permission: 'delete' })">
              <v-btn :loading="deleteEncashmentLoading" size="sm" @click="deleteEncashment(item)">
                <v-icon dbmblueprimary>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table-server>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn id="addRelation-submit-btn" color="primary" @click="addEncashment()"><span v-html="$t('encashments_add')" /></v-btn>
      </v-col>
    </v-row>

    <v-dialog :retain-focus="false" v-model="dialog" max-width="30%" id="encashments-dialog" persistent>
      <v-card>
        <v-form @submit.prevent="createEncashment()" ref="encashmentForm">
          <v-card-title><span v-html="$t('encashments_add_new_relation')" /></v-card-title>
          <v-card-text>
            <v-layout row justify-center>
              <v-overlay v-model="createLoading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
                <v-container fill-height>
                  <v-layout row justify-center align-center>
                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                  </v-layout>
                </v-container>
              </v-overlay>
            </v-layout>
            <v-select
              id="formParts"
              required
              v-model="encashment.legalEntityId"
              :items="processingEncashmentOrganisations"
              item-value="id"
              item-title="name"
              :label="$t('encashments_organisations')"
            ></v-select>
            <DbmDatePicker v-model="encashment.validFrom" :dateLabel="$t('encashments_valid_from')" :required="true" />
            <DbmDatePicker v-model="encashment.validUntil" :dateLabel="$t('encashments_valid_until')" :clearable="true" />
          </v-card-text>
          <v-card-actions>
            <v-btn id="displayEncashment-submit-btn" class="m-2" color="primary" type="submit"><span v-html="$t('encashments_submit')" /></v-btn>
            <v-btn id="displayEncashment-close-btn" class="m-2" color="secondary" @click="closeDialog()"><span v-html="$t('close')" /></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { mapGetters, mapActions } from 'vuex'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { DTSHeader } from '@/services/BackendService'
import { useOrganisationsStore } from '@/store/enums/OrganisationsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'displayEncashments',
  props: {
    id: { type: Number, default: 0 }
  },
  components: {
    DbmDatePicker
  },
  data() {
    return {
      encashments: [],
      encashment: {
        validFrom: undefined,
        validUntil: undefined
      },
      dialog: false,
      loading: false,
      createLoading: false,
      deleteEncashmentLoading: false,
      encashmentfields: <DTSHeader[]>[
        {
          title: this.$t('search_contact_name1'),
          key: 'legalEntityName1',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('search_contact_name2'),
          key: 'legalEntityName2',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('encashments_valid_from'),
          key: 'validFrom',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('encashments_valid_until'),
          key: 'validUntil',
          sortable: false,
          width: '25%'
        },
        // Delete icon
        {
          title: '',
          key: 'delete',
          sortable: false
        }
      ]
    }
  },
  computed: {
    processingEncashmentOrganisations() {
      return useOrganisationsStore().processingEncashmentOrganisations
    }
  },
  methods: {
    async deleteEncashment(item) {
      const formatter = new CustomFormatter()
      const formattedMessage = formatter.interpolate(this.$t('masterdata_dairies_encashments_confirm_delete'), [item.legalEntityName1])
      if (!confirm(formattedMessage)) return

      this.deleteEncashmentLoading = true
      try {
        const result = await this.axios.delete(
          apiURL + '/encashments/' + item.id,
          { params: { id: item.id } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.encashments = await this.loadEncashments()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteEncashmentLoading = false
      }
    },
    addEncashment() {
      this.dialog = true
      this.loading = false
    },
    reset() {
      this.encashment.validFrom = undefined
      this.encashment.validUntil = undefined
      this.encashment.legalEntityId = undefined
    },
    closeDialog() {
      this.reset()
      this.dialog = false
    },
    async loadEncashments() {
      try {
        this.loading = true
        const result = await this.axios.get(`${apiURL}/encashments/findEncashmentsByDairyId`, {
          params: { dairyId: this.$route.params.id },
          headers: { Accept: 'application/json' }
        })
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async createEncashment() {
      if (!(await this.$refs.encashmentForm.validate()).valid) return
      try {
        this.encashment.dairyId = this.$route.params.id
        this.createLoading = true
        const result = await this.axios.post(`${apiURL}/encashments`, {
          ...this.encashment
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.createLoading = false
        this.encashments = await this.loadEncashments()
      }
    },
    async updateEncashment(item) {
      const updatedItem = {
        dairyId: item.dairyId,
        validFrom: item.validFrom,
        validUntil: item.validUntil
      }
      try {
        this.loading = true
        const result = await this.axios.patch(`${apiURL}/encashments/${item.id}`, {
          ...updatedItem
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    }
  },
  beforeMount() {
    useOrganisationsStore().fetchProcessingEncashmentOrganisations()
  },
  async mounted() {
    this.encashments = await this.loadEncashments()
  }
})
</script>
