<template>
  <div>
    <h2 v-html="$t('masterdata_dairies_bewirtschaftet_title')"></h2>
    <v-row>
      <v-col>
        <!-- Bewirtschaftet -->
        <div v-if="bewirtschafter && bewirtschafter.length > 0">
          <!-- existing relations -->
          <v-data-table-server
            density="compact"
            :headers="fields"
            :items="bewirtschafter"
            :hide-default-footer="true"
            disable-pagination
            :loading="loading"
            class="custom-simple-table"
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              <dbmDatePicker class="nomessage" v-model="item.validFrom" :required="true" @change="patchRow(item)"></dbmDatePicker>
            </template>
            <template v-slot:[`item.validUntil`]="{ item }">
              <dbmDatePicker class="nomessage" v-model="item.validUntil" :clearable="true" @change="patchRow(item)"></dbmDatePicker>
            </template>
            <template v-slot:[`item.delete`]="{ item }" v-if="$privileges.has({ path: '/managements', permission: 'delete' })">
              <v-btn :loading="deleteManagementLoading" size="sm" @click="deleteManagement(item)">
                <v-icon dbmblueprimary>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table-server>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn id="addRelation-submit-btn" color="primary" @click="addRelation()"><span v-html="$t('affiliations_add')" /></v-btn>
      </v-col>
    </v-row>

    <!-- Search/Select LegalEnity -->
    <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="masterdata-dairies-dialog">
      <v-card>
        <v-card-title><span v-html="$t('masterdata_dairies_add_new_relation')" /></v-card-title>
        <v-card-text>
          <v-layout row justify-center>
            <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                </v-layout>
              </v-container>
            </v-overlay>
          </v-layout>
          <v-row class="row search spacer-md">
            <v-col cols="6" md="6" xl="4">
              <dbm-extended-legal-entity-picker
                v-model="selected.contactParentId"
                :label="$t('masterdata_dairy_relation_picker')"
                name="contactParentIdPickerVue"
                :rules="[$rules.required]"
                :cards="['searchLocalUnit', 'searchFarm', 'searchContact']"
              />
              <DbmDatePicker v-model="selected.validFrom" :dateLabel="$t('masterdata_dairies_valid_from')" :clearable="true" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn id="submit-btn" class="m-2" color="primary" @click="submit(id)" type="submit"><span v-html="$t('masterdata_dairies_add_button')" /></v-btn>
          <v-btn id="close-btn" class="m-2" color="secondary" @click="closeDialog()" type="submit"><span v-html="$t('close')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { format } from 'date-fns'
import { defineComponent } from 'vue'
import DbmExtendedLegalEntityPicker from '@/components/pickers/dbmExtendedLegalEntityPicker.vue'

let defaultSelection = { validFrom: null, contactParentId: null }
export default defineComponent({
  name: 'displayFarmed',
  components: {
    DbmExtendedLegalEntityPicker,
    DbmDatePicker: DbmDatePicker
  },
  props: { dairyId: { type: Number, required: true } },

  data() {
    return {
      loading: false,
      dialogsettings: { loading: false, totalElements: 0 },
      bewirtschafter: [],
      selected: { ...defaultSelection },
      deleteManagementLoading: false,
      managementId: 0,
      dialog: false
    }
  },
  methods: {
    async deleteManagement(item) {
      const formatter = new CustomFormatter()
      const formattedMessage = formatter.interpolate(this.$t('masterdata_dairies_managements_confirm_delete'), [item.legalEntityAddressName1])
      if (!confirm(formattedMessage)) return

      this.deleteManagementLoading = true
      try {
        const result = await this.axios.delete(
          apiURL + '/managements/' + item.managementId,
          { params: { id: item.managementId } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.bewirtschafter = await this.loadRelations(this.dairyId)
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteManagementLoading = false
      }
    },
    async loadRelations(itemId) {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/dairies/' + itemId + '/managements', { headers: { Accept: 'application/json' } })
        const items = await response.data
        return Array.isArray(items) ? items : []
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async patchRow(item) {
      this.loading = true
      try {
        const result = this.axios.patch(`/api/managements/${item.managementId}`, {
          validFrom: format(item.validFrom, 'yyyy-MM-dd'),
          validUntil: item.validUntil ? format(item.validUntil, 'yyyy-MM-dd') : null
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async submit() {
      this.loading = true
      try {
        const result = await this.axios.post(
          apiURL + '/managements/',
          {
            validFrom: format(this.selected.validFrom, 'yyyy-MM-dd'),
            legalEntity: { id: this.selected.contactParentId },
            localUnit: { id: this.dairyId }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )

        emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        return result.data
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
        this.bewirtschafter = await this.loadRelations(this.dairyId)
      }
    },
    addRelation() {
      this.dialog = true
      this.loading = false
    },
    closeDialog() {
      this.reset()
      this.dialog = false
    },
    reset() {
      this.selected = { ...defaultSelection }
    }
  },
  async mounted() {
    this.bewirtschafter = await this.loadRelations(this.dairyId)
  }
})
</script>
