<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <h2 v-html="$t('dairies_general_stammdaten')"></h2>
        <v-row v-if="model" dense>
          <!-- MBH Ident -->
          <v-col cols="12" sm="12" md="6">
            <v-text-field :disabled="readonly" id="MBH-Ident" v-model="model.ident" :label="$t('dairies_mbh_ident')" type="number" readonly />
          </v-col>
          <!-- Betriebsform -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              :disabled="readonly"
              v-model="model.dairyType"
              :label="$t('dairies_dairytype')"
              :items="dairyTypes"
              :item-title="this.$getLangKey()"
              item-value="name"
              clearable
            />
          </v-col>
          <!-- BUR -->
          <v-col cols="12" sm="12" md="6">
            <v-text-field :disabled="readonly" id="bur" v-model="model.berNo" :label="$t('dairies_berno')" type="string" />
          </v-col>
          <!-- EGID -->
          <v-col cols="12" sm="12" md="6">
            <v-text-field :disabled="readonly" id="egid" v-model="model.egid" :label="$t('dairies_egid')" type="number" />
          </v-col>
          <!-- Betriebszulassungsnummer -->
          <v-col cols="12" sm="12" md="6">
            <v-text-field :disabled="readonly" id="permitno" v-model="model.permitNo" :label="$t('dairies_farm_permit_number')" type="number" />
          </v-col>
          <!-- AGIS-ID -->
          <v-col cols="12" sm="12" md="6">
            <v-text-field :disabled="readonly" id="bur" v-model="model.agisId" :label="$t('dairies_agisid')" type="number" />
          </v-col>
          <!-- Kanton -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              :disabled="readonly"
              id="canton"
              v-model="model.canton.id"
              :label="$t('dairies_canton_no')"
              :items="cantons"
              :item-title="this.$getLangKey()"
              item-value="id"
            />
          </v-col>
          <!-- Zuständiger MA TSM -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              :disabled="readonly"
              v-model="model.responsibilityGroup"
              :label="$t('dairies_personid')"
              :items="responsibilityGroups"
              :item-title="this.$getLangKey()"
              item-value="name"
              @change="updateTsmOffice(model.responsibilityGroup)"
            />
          </v-col>
          <!-- TSM-Stelle -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              id="dairies_tsmoffice"
              v-if="$privileges.has({ path: '/persons', permission: 'read' })"
              v-model="model.tsmOffice"
              :label="$t('dairies_tsmoffice')"
              :items="workingPlaces"
              :item-title="this.$getLangKey()"
              item-value="name"
              disabled
              variant="underlined"
              clearable
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <DisplaySubsidyCreditor backendApi="dairies" :id="parseInt(this.$route.params.id)" creditorType="processing" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <h2 v-html="$t('daieries_reporting_obligation')"></h2>
        <v-row dense>
          <!-- MPD1 Milchmengenmeldung -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              :disabled="readonly"
              v-model="model.productionReportingObligation"
              :label="$t('dairies_reporting_obligation_MPD1')"
              :items="reportingObligations"
              :item-title="this.$getLangKey()"
              item-value="name"
            />
          </v-col>
          <!-- TSM1 Verwertungsdaten -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              :disabled="readonly"
              v-model="model.processingReportingObligation"
              :label="$t('dairies_reporting_obligation_TSM1')"
              :items="reportingObligations"
              :item-title="this.$getLangKey()"
              item-value="name"
            />
          </v-col>
          <!-- BOM -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              :disabled="readonly"
              v-model="model.segmentationReportingObligation"
              :label="$t('dairies_reporting_obligation_BOM')"
              :items="reportingObligations"
              :item-title="this.$getLangKey()"
              item-value="name"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <!-- Inaktiv per - valid until-->
            <DbmDatePicker :readonly="readonly" v-model="model.validUntil" :dateLabel="$t('masterdata_inactive_by')" :clearable="true" />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <!-- Erste Anmeldung -->
            <DbmDatePicker :readonly="readonly" v-model="model.registrationInitial" :dateLabel="$t('masterdata_registration_initial')" :clearable="true" />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <!-- Anmeldung bestätigt -->
            <DbmDatePicker :readonly="readonly" v-model="model.registrationConfirmed" :dateLabel="$t('masterdata_registration_confirmed')" :clearable="true" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="$getBeta()" class="beta">
        <h2 v-html="$t('masterdata_farms_hierarchy')"></h2>
        <hierarchy type="Dairy" :id="$route.params.id"></hierarchy>
      </v-col>
      <v-col className="item" sm="12" md="6" lg="6">
        <rdhComponent
          :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
          :remarks_show="true"
          :remarks_tag="'dairies_' + $route.params.id"
          :documents_show="true"
          :documents_tag="'dairies_' + $route.params.id"
          :history_show="true"
          :history_url="'/dairies/' + $route.params.id"
          ref="rdh"
        ></rdhComponent>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-btn id="btn_save" color="primary" @click="onSubmit" v-if="!readonly">
          <span v-html="$t('products_button_save')" />
        </v-btn>
        <v-btn color="secondary" @click="navigateBackToSearch"><span v-html="$t('dairies_back_button')" /></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { emitter, apiURL } from '@/main'
import { mapGetters, mapActions } from 'vuex'
import { showError } from '@/services/axios'
import { privileges } from '@/services/privileges'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import DisplaySubsidyCreditor from '@/components/displayCards/displaySubsidyCreditor.vue'
import rdhComponent from '@/components/rdh/rdhComponent'
import Hierarchy from '@/components/hierarchy.vue'
import _ from 'lodash'
import { useCantonsStore } from '@/store/enums/CantonsStore'
import { useDairyTypesStore } from '@/store/enums/DairyTypesStore'
import { useReportingObligationsStore } from '@/store/enums/ReportingObligationsStore'
import { useResponsibilityGroupsStore } from '@/store/enums/ResponsibilityGroupsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'dairiesOverview',
  props: { dairy: { type: Object, required: true } },
  components: {
    DbmDatePicker: DbmDatePicker,
    DisplaySubsidyCreditor: DisplaySubsidyCreditor,
    rdhComponent: rdhComponent,
    hierarchy: Hierarchy
  },
  data() {
    return {
      apiURL: apiURL + '/dairies/',
      model: <any>{
        id: undefined,
        canton: {
          id: undefined
        },
        person: {
          id: undefined
        }
      },
      privileges: privileges,
      bern: 'Bern',
      lausanne: 'Lausanne',
      dairyAddress: undefined
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/dairies', permission: 'write' })
    },
    dairyTypes() {
      return useDairyTypesStore().dairyTypes
    },
    responsibilityGroups() {
      return useResponsibilityGroupsStore().responsibilityGroups
    },
    workingPlaces() {
      return useResponsibilityGroupsStore().workingPlaces
    },
    cantons() {
      return useCantonsStore().Cantons
    },
    reportingObligations() {
      return useReportingObligationsStore().reportingObligations
    }
  },
  methods: {
    updateTsmOffice(responsibilityGroup: any) {
      if (responsibilityGroup.substring(0, 7) == 'LE_MONT') {
        this.model.tsmOffice = 'LAUSANNE'
      } else {
        this.model.tsmOffice = 'BERN'
      }
    },
    async loadAddress(dairyId: number) {
      try {
        const response = await this.axios.get(apiURL + '/dairies/' + dairyId + '' + '/defaultAddress', { headers: { Accept: 'application/json' } })
        return response.data?.formattedAddress
      } catch (e) {
        showError(e)
      }
    },
    async onSubmit() {
      try {
        const result = await this.axios.patch(
          apiURL + '/dairies/' + this.dairy.id,
          {
            ..._.omit(this.model, ['formattedAddress', 'formattedName']),
            validUntil: this.$formatDateTime(this.model.validUntil)
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          if (privileges.has({ path: '/histories', permission: 'read' })) {
            emitter.emit('loadHistory')
          }
        }
      } catch (e) {
        showError(e)
      }
    },
    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_dairies_search'
        })
        .catch((e) => {
          /* duplicated route */
        })
    }
  },
  watch: {
    query: {
      handler() {
        this.$router.push({ name: 'dairies' }).catch((e) => {
          /* duplicated route */
        })
      },
      deep: true
    }
  },
  beforeMount() {
    useDairyTypesStore().fetchDairyTypes()
    useCantonsStore().fetchCantons()
  },
  async beforeMount() {
    useResponsibilityGroupsStore().fetchResponsibilityGroups()
    useResponsibilityGroupsStore().fetchWorkingPlaces()
    useReportingObligationsStore().fetchReportingObligations()
    this.dairyAddress = await this.loadAddress(this.dairy.id)
    this.model = { ...this.dairy }
  }
})
</script>
