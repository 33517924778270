<template>
  <div>
    <h2 v-html="$t('masterdata_dairies_affiliations_title')"></h2>
    <v-row>
      <v-col>
        <div v-if="affiliations && affiliations.length > 0">
          <v-data-table-server
            density="compact"
            :headers="affiliationFields"
            :items="affiliations"
            :hide-default-footer="true"
            disable-pagination
            :loading="loading"
            class="custom-simple-table"
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              <dbmDatePicker class="nomessage" v-model="item.validFrom" :required="true" @change="updateAffiliation(item)"></dbmDatePicker>
            </template>
            <template v-slot:[`item.validUntil`]="{ item }">
              <dbmDatePicker class="nomessage" v-model="item.validUntil" :clearable="true" @change="updateAffiliation(item)"></dbmDatePicker>
            </template>
            <template v-slot:[`item.memberNo`]="{ item }">
              <v-text-field class="nomessage" v-model="item.memberNo" @change="updateAffiliation(item)" />
            </template>
            <template v-slot:[`item.delete`]="{ item }" v-if="$privileges.has({ path: '/managements', permission: 'delete' })">
              <v-btn :loading="deleteAffiliationLoading" size="sm" @click="deleteAffiliation(item)">
                <v-icon dbmblueprimary>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table-server>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn id="addRelation-submit-btn" color="primary" @click="addAffiliation"><span v-html="$t('affiliations_add')" /></v-btn>
      </v-col>
    </v-row>

    <v-dialog :retain-focus="false" v-model="dialog" max-width="30%" id="affiliations-dialog" persistent>
      <v-card>
        <v-form @submit.prevent="createAffiliation()" ref="affiliationForm">
          <v-card-title><span v-html="$t('affiliations_add_new_relation')" /></v-card-title>
          <v-card-text>
            <v-layout row justify-center>
              <v-overlay v-model="createLoading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
                <v-container fill-height>
                  <v-layout row justify-center align-center>
                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                  </v-layout>
                </v-container>
              </v-overlay>
            </v-layout>
            <v-select
              id="buyerOrganisations"
              :rules="[(v) => !!v]"
              required
              v-model="affiliation.legalEntity.id"
              :items="buyerOrganisations"
              item-value="id"
              item-title="name"
              :label="$t('affiliations_organisations')"
            ></v-select>

            <DbmDatePicker v-model="affiliation.validFrom" :dateLabel="$t('affiliations_valid_from')" :required="true" />
            <DbmDatePicker v-model="affiliation.validUntil" :dateLabel="$t('affiliations_valid_until')" :clearable="true" />
            <v-text-field id="affiliation_member_no" v-model="affiliation.memberNo" :label="$t('affiliations_member_no')" type="number" />
          </v-card-text>
          <v-card-actions>
            <v-btn id="displayaffiliations-submit-btn" class="m-2" color="primary" type="submit"><span v-html="$t('affiliations_submit')" /></v-btn>
            <v-btn id="displayaffiliations-close-btn" class="m-2" color="secondary" @click="closeDialog()"><span v-html="$t('close')" /></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { DTSHeader } from '@/services/BackendService'
import { useOrganisationsStore } from '@/store/enums/OrganisationsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'displayAffiliations',
  props: {
    id: { type: Number, default: 0 }
  },
  components: {
    DbmDatePicker
  },
  data() {
    return {
      affiliations: [],
      affiliation: {
        validFrom: undefined,
        validUntil: undefined,
        localUnit: {
          id: undefined
        },
        legalEntity: {
          id: undefined
        }
      },
      dialog: false,
      loading: false,
      createLoading: false,
      deleteAffiliationLoading: false,
      affiliationfields: <DTSHeader[]>[
        {
          title: this.$t('search_contact_name1'),
          key: 'legalEntityAddressName1',
          sortable: false,
          width: '20%'
        },
        {
          title: this.$t('search_contact_name2'),
          key: 'legalEntityAddressName2',
          sortable: false,
          width: '20%'
        },
        {
          title: this.$t('affiliations_member_no'),
          key: 'memberNo',
          sortable: false,
          width: '10%'
        },
        {
          title: this.$t('affiliations_valid_from'),
          key: 'validFrom',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('affiliations_valid_until'),
          key: 'validUntil',
          sortable: false,
          width: '25%'
        },
        // Delete icon
        {
          title: '',
          key: 'delete',
          sortable: false
        }
      ]
    }
  },
  computed: {
    buyerOrganisations() {
      return useOrganisationsStore().buyerOrganisations
    }
  },
  methods: {
    async deleteAffiliation(item) {
      const formatter = new CustomFormatter()
      const formattedMessage = formatter.interpolate(this.$t('masterdata_dairies_affiliations_confirm_delete'), [item.legalEntityAddressName1])
      if (!confirm(formattedMessage)) return

      this.deleteAffiliationLoading = true
      try {
        const result = await this.axios.delete(
          apiURL + '/affiliations/' + item.affiliationId,
          { params: { id: item.affiliationId } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.affiliations = await this.loadAffiliations()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteAffiliationLoading = false
      }
    },
    addAffiliation() {
      this.dialog = true
      this.loading = false
    },
    reset() {
      this.affiliation.validFrom = undefined
      this.affiliation.validUntil = undefined
      this.affiliation.memberNo = undefined
    },

    closeDialog() {
      this.reset()
      this.dialog = false
    },
    async loadAffiliations() {
      try {
        this.loading = true
        const result = await this.axios.get(`${apiURL}/dairies/${this.$route.params.id}/affiliations`, {
          headers: { Accept: 'application/json' }
        })
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async createAffiliation() {
      if (!(await this.$refs.affiliationForm.validate()).valid) return
      try {
        this.createLoading = true
        this.affiliation.localUnit.id = this.$route.params.id
        const result = await this.axios.post(`${apiURL}/affiliations`, {
          ...this.affiliation
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.createLoading = false
        this.affiliations = await this.loadAffiliations()
      }
    },
    async updateAffiliation(item) {
      const updatedItem = {
        id: item.affiliationId,
        validFrom: item.validFrom,
        validUntil: item.validUntil,
        memberNo: item.memberNo
      }
      try {
        this.loading = true
        const result = await this.axios.patch(`${apiURL}/affiliations/${updatedItem.id}`, {
          ...updatedItem
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    }
  },
  beforMount() {
    useOrganisationsStore().fetchBuyerOrganisations()
  },
  async mounted() {
    this.affiliations = await this.loadAffiliations()
  }
})
</script>
